export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const TOAST_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    WAITING: 'waiting',
};

const defaultOptions = {
    color: '#6796e6',
};

export function createToast(options: any) {
    const id = Math.floor(Math.random() * 100 + 1);
    return {
        ...defaultOptions,
        ...options,
        id,
    };
}
