import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { IRouteProps } from '..';
import styled from 'styled-components';
import { COMPONENT_IDS } from '../../app/App.constant';
import enhance from '../MainRoute/MainRoute.enhance';
import { IProps } from '../MainRoute/MainRoute.inteface';
// import Toast from 'src/components/Toast/Toast';
// import scrollToTopImage from 'src/assets/image/scroll-to-top.png';

const Styled = styled.div<{ height: number }>`
    min-height: ${({ height }) => `${height}px`};
    // margin-top: 80px;

    .ant-back-top {
        opacity: 0.7;
    }
`;

const MainRoute = (props: IProps & any) => {
    const { routes } = props;

    const [height, setHeight] = React.useState(0);
    const getContentHeight = () => {
        const headerElement = document.getElementById(COMPONENT_IDS.HEADER) as HTMLFormElement;
        const headerHeight = headerElement ? headerElement.clientHeight : 0;
        const footerElement = document.getElementById(COMPONENT_IDS.FOOTER) as HTMLFormElement;
        const footerHeight = footerElement ? footerElement.clientHeight : 0;
        const windowHeight = window.innerHeight;
        setHeight(windowHeight - headerHeight - footerHeight);
    };
    React.useEffect(() => {
        getContentHeight();
    }, []);

    if (!routes || routes.length === 0) return <div />;
    return (
        <Styled
            className="style-wrap-main"
            height={height}
            // style={{ marginTop: height === 0 ? height : isMobile ? 40 : 80 }}
        >
            {/* height === 0 ? height : 80 */}
            {/* <Toast /> */}
            <Routes>
                {routes.map((route: IRouteProps) => (
                    <Route {...route} key={route.path} />
                ))}
            </Routes>
            {/* <BackTop>
                <div className="scroll-to-top">
                    <Image preview={false} src={scrollToTopImage} />
                </div>
            </BackTop> */}
        </Styled>
    );
};

export default enhance(React.memo(MainRoute));
