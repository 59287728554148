import { IModalState } from './Modal.type';
import { ACTION_TYPES } from './Modal.constant';

const initialState: IModalState = {
    visible: false,
    data: null,
    width: 444, // 678
    title: undefined,
    onClose: undefined,
    autoClose: false,
    customModal: false,
};

const reducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ACTION_TYPES.TOGGLE_MODAL: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ACTION_TYPES.CLOSE_MODAL: {
            return {
                ...state,
                ...initialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
