import React from 'react';
import { Modal as AntdModal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ITheme } from 'styled-components';
import { CircleCloseIcon } from '../../components/Icons';
import { modalSelector } from './Modal.selector';
import { actionCloseModal } from './Modal.actions';

export const Wrapper = styled.div`
    // padding: 32px;
    // border-radius: 6px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${({ theme }) => theme.white};
    > div {
        width: 100%;
    }
    .btn-close {
        margin: auto 0 -46px auto;
        z-index: 99;
    }
    .header-title {
        font-family: 'Karmatic Arcade';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        letter-spacing: 2px;
        width: 100%;
    }
    // &:hover {
    //     box-shadow: 0px 0px 10px ${({ theme }) => theme.green6};
    // }
    ${({ theme }) => theme.mediaWidth.upToMedium`
        .header-title {
            font-size: 26px;
        }
    `}
`;

const Modal = React.memo(() => {
    const dispatch = useDispatch();
    const { visible, data, width, title, onClose, autoClose, customModal } = useSelector(modalSelector);
    const handleClose = () => {
        dispatch(actionCloseModal());
        typeof onClose === 'function' && onClose();
    };
    React.useEffect(() => {
        if (autoClose) {
            setTimeout(() => {
                handleClose();
            }, 2000);
        }
    }, [autoClose]);
    if (!data) return null;
    if (customModal) {
        return (
            <AntdModal open={visible} destroyOnClose footer={null} width={width} onCancel={handleClose}>
                {data}
            </AntdModal>
        );
    }
    return (
        <AntdModal open={visible} destroyOnClose footer={null} width={width} onCancel={handleClose}>
            <Wrapper>
                {/* <CircleCloseIcon onClick={handleClose} className="btn-close" /> */}
                {!!title && <p className="fw-bold fs-verylarge disable-pointer green-text header-title">{title}</p>}
                {data}
            </Wrapper>
        </AntdModal>
    );
});

export default Modal;
