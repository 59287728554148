import Lottie from 'lottie-react';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import loadingAnimation from './loading.json';
import { loadingSelector } from './Loading.selector';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }

    .content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 130px;
        height: 130px;
    }
`;

const Loading = React.memo(() => {
    const { visible } = useSelector(loadingSelector);

    return (
        <>
            {visible && (
                <Wrapper>
                    <div className="backdrop">
                        <div className="content">
                            <Lottie animationData={loadingAnimation} />
                        </div>
                    </div>
                </Wrapper>
            )}
        </>
    );
});

export default Loading;
