import React, { lazy } from 'react';
import { IRouteProps } from '..';

export const route = '/';

const Element = React.lazy(() => import('../LandingPage/LandingPage'));

const LandingPageRoute: IRouteProps = {
    path: route,
    element: (
        <React.Suspense fallback={<></>}>
            <Element />
        </React.Suspense>
    ),
    name: 'LandingPage',
};

export default LandingPageRoute;
