import { IModalState } from './Modal.type';
import { ACTION_TYPES } from './Modal.constant';

export const actionToggleModal = (payload: IModalState) => ({
    type: ACTION_TYPES.TOGGLE_MODAL,
    payload,
});

export const actionCloseModal = () => ({
    type: ACTION_TYPES.CLOSE_MODAL,
});
