import React, { lazy } from 'react';
import { IRouteProps } from '..';

export const route = '/swap';

const Element = React.lazy(() => import('../UniSwap/UniSwap'));

const UniSwapRoute: IRouteProps = {
    path: route,
    element: (
        <React.Suspense fallback={<></>}>
            <Element />
        </React.Suspense>
    ),
    name: 'UniSwap',
};

export default UniSwapRoute;
