import { ILoadingState } from './Loading.type';
import { ACTION_TYPES } from './Loading.constant';

const initialState: ILoadingState = {
    visible: false,
};

const reducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ACTION_TYPES.OPEN_LOADING: {
            return {
                ...state,
                visible: true,
            };
        }
        case ACTION_TYPES.CLOSE_LOADING: {
            return {
                ...state,
                ...initialState,
            };
        }
        default:
            return state;
    }
};

export default reducer;
