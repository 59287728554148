import { combineReducers } from 'redux';
import { ADD_TOAST, REMOVE_TOAST } from './Toast.action';

function toastsReducer(state = [], action: { type: string; payload: any }) {
    const { payload, type } = action;

    switch (type) {
        case ADD_TOAST:
            return [payload, ...state];

        case REMOVE_TOAST:
            return state.filter((toast: any) => toast.id !== payload);

        default:
            return state;
    }
}

export default combineReducers({
    toasts: toastsReducer,
});
